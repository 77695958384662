import React, { useEffect, useRef } from "react";

const Video = ({ videoSrcURL, videoTitle, ...props }) => {
  return (
    <div className="w-full" style={{ height: "80vh" }}>
      <video
        autoPlay
        muted
        loop
        controls
        className="w-full bg-black"
        style={{ height: "80vh" }}
      >
        <source src={videoSrcURL} type="video/mp4" title={videoTitle} />
      </video>
    </div>
  );
};

export default Video;
