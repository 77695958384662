import React from 'react'
import { Button } from '@material-ui/core'
import questions from '../../../images/pdf/2020-15-questions.pdf'

export default function Questions() {
    return (
        <>
            <a href={questions} target="_blank">
                <Button variant="contained" color="primary"><span className="text-white">Download Buyers Guide</span></Button>
            </a>
        </>
    )
}